import clsx from 'clsx';
import Link from 'next/link';

import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';

import type {ProjectDetails} from '../../shared/types';

type Props = {
	project: ProjectDetails;
};

export const ProjectCard = ({project}: Props) => {
	const {title, shortLead, href, imageWeb} = project;

	return (
		<Link
			href={href}
			className={clsx(
				'inline-block',
				'hover:text-primary-light',
				'transition-colors',
				'w-full',
			)}
		>
			<div
				className={clsx(
					'bg-beige-200',
					'aspect-750/500',
					'flex',
					'justify-center',
					'items-center',
					'text-primary/50',
					'mb-4',
				)}
			>
				{imageWeb ? (
					<ImageBlock
						height={imageWeb.image.height}
						src={imageWeb.image.src}
						width={imageWeb.image.width}
						alt={imageWeb.altText ?? ''}
						defaultSizes="(max-width: 768px) 90vw, (max-width: 1750px) 40vw, 755px"
					/>
				) : (
					<span>Mangler bilde</span>
				)}
			</div>

			<Paragraph asChild className={clsx('font-bold', 'md:text-xl', 'mb-1')}>
				<h2>{title}</h2>
			</Paragraph>

			<Paragraph className={clsx('text-lg', 'md:text-xl')}>{shortLead}</Paragraph>
		</Link>
	);
};
