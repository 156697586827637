import clsx from 'clsx';

import {Figure} from '@/modules/foundation/components/figure';
import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import type {ImageWeb} from '@/modules/foundation/components/image/types';

interface Props {
	image: ImageWeb;
}

const classNameOverride = {
	figure: 'my-6',
};

export const PortableImage = ({image}: Props) => {
	if (!image) {
		return null;
	}

	const width = image.width === 'fullTextWidth' ? '100%' : '50%';

	let alignmentClass = 'justify-center';
	switch (image.alignment) {
		case 'center':
			alignmentClass = 'justify-center';
			break;
		case 'left':
			alignmentClass = 'justify-start';
			break;
		case 'right':
			alignmentClass = 'justify-end';
			break;
	}

	if (image.caption || image.credit) {
		return (
			<div className={clsx('flex', 'relative', alignmentClass)}>
				<div style={{width: width}}>
					<Figure
						credit={image.credit}
						caption={image.caption}
						classNameOverride={classNameOverride}
					>
						<ImageBlock
							height={image.image.height}
							src={image.image.src}
							width={image.image.width}
							alt={image.altText ?? ''}
							defaultSizes="(max-width: 1040px) 90vw, 910px"
						/>
					</Figure>
				</div>
			</div>
		);
	}

	return (
		<div className={clsx('flex', 'my-8', 'relative', alignmentClass)}>
			<ImageBlock
				height={image.image.height}
				src={image.image.src}
				width={image.image.width}
				alt={image.altText ?? ''}
				defaultSizes="(max-width: 1040px) 90vw, 910px"
				style={{width: width}}
			/>
		</div>
	);
};
