import type {FC} from 'react';
import clsx from 'clsx';

import {Button} from '@/modules/foundation/components/button';
import {ArrowRightIcon} from '@/modules/foundation/components/icons/arrow-right-icon';
import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import {createPhoneLink, formatPhoneNumber} from '@/modules/foundation/shared/format';

import type {Person} from '../../shared/types';

export interface PersonCardProps {
	person: Person;
}

export const PersonCard: FC<PersonCardProps> = ({person}) => {
	const {name, mainImage, hoverImage, phone, email, roles} = person;

	return (
		<div className={clsx('relative')}>
			<div
				className={clsx(
					'relative',
					'group',
					'aspect-5/7',
					'isolate',
					'bg-beige-200',
					'grid',
					'place-items-center',
					'mb-4',
				)}
			>
				{hoverImage || mainImage ? (
					<>
						{mainImage && (
							<ImageBlock
								alt={mainImage.altText ?? ''}
								height={mainImage.image.height}
								width={mainImage.image.width}
								src={mainImage.image.src}
								className={clsx(hoverImage && 'group-hover:opacity-0', 'z-10')}
								defaultSizes="(max-width: 640px) 100vw, 486px"
							/>
						)}

						{hoverImage && (
							<ImageBlock
								alt={hoverImage.altText ?? ''}
								height={hoverImage.image.height}
								width={hoverImage.image.width}
								src={hoverImage.image.src}
								className={clsx('absolute', 'inset-0', '-z-10')}
								defaultSizes="(max-width: 640px) 100vw, 486px"
							/>
						)}
					</>
				) : (
					<span>Mangler bilde</span>
				)}
			</div>

			<Heading level={2} size="xsmall" className={clsx('text-primary', 'mb-1')}>
				{name}
			</Heading>

			{roles && roles.length ? (
				<ul className={clsx('flex', 'flex-wrap', 'gap-x-5', 'mb-5', 'md:mb-3')}>
					{roles.map((service) => (
						<li
							key={service}
							className={clsx(
								'relative',
								'after:absolute',
								"after:content-['/']",
								"last-of-type:after:content-['']",
								'after:right-[-14px]',
								'after:top-0',
								'after:text-base',
								'after:text-secondary-dark',
							)}
						>
							<Paragraph asChild size="medium">
								<span>{service}</span>
							</Paragraph>
						</li>
					))}
				</ul>
			) : null}

			<div className={clsx('grid', 'justify-start', 'gap-3', 'md:gap-1')}>
				{email && (
					<div>
						<Paragraph asChild className="hidden md:block">
							<a
								href={`mailto:${email}`}
								className={clsx('hover:text-primary/50', 'transition-colors')}
							>
								{email}
							</a>
						</Paragraph>
						<Button asChild className="md:hidden">
							<a href={`mailto:${email}`}>
								{email} <ArrowRightIcon aria-hidden />
							</a>
						</Button>
					</div>
				)}

				{phone && (
					<div>
						<Paragraph asChild className="hidden md:block">
							<a
								href={createPhoneLink(phone)}
								className={clsx(
									'hover:text-primary/50',
									'transition-colors',
									'inline-block',
								)}
							>
								{formatPhoneNumber(phone)}
							</a>
						</Paragraph>
						<Button asChild className="md:hidden">
							<a href={createPhoneLink(phone)}>
								{formatPhoneNumber(phone)}
								<ArrowRightIcon aria-hidden />
							</a>
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};
