import clsx from 'clsx';
import Link from 'next/link';

import {Container} from '@/modules/foundation/components/container';
import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';

type Props = {
	heading: string;
	description: string;
	imageWeb?: ImageWeb;
	link?: {
		href: string;
		targetBlank: boolean;
	};
};

export const MobileCard = ({heading, description, imageWeb, link}: Props) => {
	return (
		<Container>
			<Link
				href={link?.href ?? '#'}
				className={clsx(
					'inline-block',
					'hover:text-primary-light',
					'transition-colors',
					'mb-10',
				)}
				{...(link?.targetBlank ? {target: '_blank', rel: 'noopener noreferrer'} : {})}
			>
				<div
					className={clsx(
						'bg-beige-200',
						'aspect-750/500',
						'flex',
						'justify-center',
						'items-center',
						'text-primary/50',
						'mb-4',
					)}
				>
					{imageWeb ? (
						<ImageBlock
							height={imageWeb.image.height}
							src={imageWeb.image.src}
							width={imageWeb.image.width}
							alt={imageWeb.altText ?? ''}
							defaultSizes="(max-width: 760px) 90vw, 676px"
						/>
					) : (
						<span>Mangler bilde</span>
					)}
				</div>

				<Paragraph asChild className={clsx('font-bold', 'md:text-xl', 'mb-1')}>
					<h2>{heading}</h2>
				</Paragraph>

				<Paragraph className={clsx('text-lg', 'md:text-2xl')}>{description}</Paragraph>
			</Link>
		</Container>
	);
};
