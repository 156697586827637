'use client';

import {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import AutoScroll from 'embla-carousel-auto-scroll';
import useEmblaCarousel from 'embla-carousel-react';

import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import {usePrefersReducedMotion} from '@/modules/foundation/hooks/usePrefersReducedMotion';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {CustomerDetails} from '../../sanity/shared/types';

type Props = {
	customers: KeyedArray<CustomerDetails>;
};

export const LogoSlider: React.FC<Props> = ({customers}) => {
	const [duplicatedLogos, setDuplicatedLogos] = useState<KeyedArray<CustomerDetails>>([]);
	const sliderRef = useRef<HTMLDivElement>(null);

	const reducedMotion = usePrefersReducedMotion();

	const [emblaRef] = useEmblaCarousel({align: 'start', startIndex: 0, loop: true}, [
		AutoScroll({active: !reducedMotion, stopOnInteraction: false, speed: 1}),
	]);

	// Duplicate logos until they cover at least twice the screen width for smooth looping
	useEffect(() => {
		if (sliderRef.current && customers.length > 0) {
			const logosWidth = customers.length * 130; // Approx width per logo
			const multiplier = Math.max(1, Math.ceil((window.innerWidth * 2) / logosWidth));

			setDuplicatedLogos(Array(multiplier).fill(customers).flat());
		} else {
			setDuplicatedLogos([]); // Set to an empty array if customers is empty
		}
	}, [customers]);

	return (
		<section ref={sliderRef} className={clsx('w-full', 'overflow-hidden', 'py-4', 'md:py-10')}>
			<div ref={emblaRef}>
				<ul
					className={clsx(
						'flex',
						'gap-9',
						'md:gap-20',
						'touch-pan-y',
						'touch-pinch-zoom',
					)}
				>
					{customers && customers.length && (
						<>
							{duplicatedLogos.map((customer, idx) => {
								if (!customer) {
									return null;
								}

								return (
									<li
										key={`${customer._key}-${idx}`}
										className={clsx(
											'shrink-0',
											'grow-0',
											'flex',
											'w-24',
											'md:w-32',
											'translate-3d',
											'items-center',
											'justify-center',
											'flex-none',
											'first-of-type:ml-9',
											'md:first-of-type:ml-20',
										)}
									>
										{customer.logo && (
											<ImageBlock
												src={customer.logo?.image.src}
												height={customer.logo?.image.height}
												width={customer.logo?.image.width}
												alt=""
												defaultSizes="(max-width: 768px) 96px, 128px"
											/>
										)}
									</li>
								);
							})}
						</>
					)}
				</ul>
			</div>
		</section>
	);
};
