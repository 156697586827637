import clsx from 'clsx';

import type {ContainerSpacing} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {ServiceArea} from '../../sanity/shared/types';

type Props = {
	serviceAreas: KeyedArray<ServiceArea>;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
};

export const ServiceAreaList = ({serviceAreas, spacingBottom, spacingTop}: Props) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			<div>
				{serviceAreas.map((serviceArea) => (
					<div
						key={serviceArea._key}
						className={clsx(
							'grid',
							'md:grid-cols-3',
							'items-center',
							'py-6',
							'md:py-12',
							'border-t',
							'border-secondary-dark',
							'last-of-type:border-b',
							'gap-x-8',
							'gap-y-4',
						)}
					>
						<Heading
							level={2}
							size="medium"
							className={clsx('max-md:text-xl', 'col-span-1', 'font-normal')}
						>
							{serviceArea.name}
						</Heading>
						<ul
							className={clsx(
								'col-span-2',
								'flex',
								'flex-wrap',
								'gap-x-4',
								'md:gap-x-6',
								'md:gap-y-2',
							)}
						>
							{serviceArea.services.map((service) => (
								<li
									key={service._key}
									className={clsx(
										'relative',
										'after:absolute',
										"after:content-['/']",
										"last-of-type:after:content-['']",
										'after:right-[-12px]',
										'md:after:right-[-18px]',
										'after:top-0',
										'max-md:after:text-base',
										'after:text-xl',
										'after:text-secondary-dark',
									)}
								>
									<Paragraph asChild size="xlarge" className="max-md:text-base">
										<span>{service.name}</span>
									</Paragraph>
								</li>
							))}
						</ul>
					</div>
				))}
			</div>
		</Container>
	);
};
